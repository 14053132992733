import React, { useEffect } from "react";
import Logo from "../Logo";
import { LazyLoadImage, LazyLoadComponent } from "react-lazy-load-image-component";
import FaceDesk from "../../../assets/img/rss/SigFacebook.png";
import FaceDeskWebp from "../../../assets/img/rss/SigFacebook.webp";
import InstaDesk from "../../../assets/img/rss/SigInstagram.png";
import InstaDeskWebp from "../../../assets/img/rss/SigInstagram.webp";
import XDesk from "../../../assets/img/rss/SigX.png";
import XDeskWebp from "../../../assets/img/rss/SigX.webp";
import YoutubeDesk from "../../../assets/img/rss/SigYoutube.png";
import YoutubeDeskWebp from "../../../assets/img/rss/SigYoutube.webp";
import TikTokDesk from "../../../assets/img/rss/SigTikTok.png";
import TikTokDeskWebp from "../../../assets/img/rss/SigTikTok.webp";
import LinkedinDesk from "../../../assets/img/rss/SigLinkedin.png";
import LinkedinDeskWebp from "../../../assets/img/rss/SigLinkedin.webp";
import FaceMobile from "../../../assets/img/rss/FacebookMobile.png";
import FaceMobileWebp from "../../../assets/img/rss/FacebookMobile.webp";
import InstaMobile from "../../../assets/img/rss/InstagramMobile.png";
import InstaMobileWebp from "../../../assets/img/rss/InstagramMobile.webp";
import XMobile from "../../../assets/img/rss/XMobile.png";
import XMobileWebp from "../../../assets/img/rss/XMobile.webp";
import YoutubeMobile from "../../../assets/img/rss/YoutubeMobile.png";
import YoutubeMobileWebp from "../../../assets/img/rss/YoutubeMobile.webp";
import TikTokMobile from "../../../assets/img/rss/TikTokMobile.png";
import TikTokMobileWebp from "../../../assets/img/rss/TikTokMobile.webp";
import LinkedinMobile from "../../../assets/img/rss/LinkedinMobile.png";
import LinkedinMobileWebp from "../../../assets/img/rss/LinkedinMobile.webp";
import ReviewButton from "../../../assets/img/buttons/review.png";
import ReviewButtonWebp from "../../../assets/img/buttons/review.webp";
import Carousel from "../Carousel/Carousel";
import { Link } from "react-router-dom";

function Footer() {
  useEffect(() => {
    const loadSnapWidget = () => {
      const script = document.createElement("script");
      script.src = "https://snapwidget.com/js/snapwidget.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    const footerObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadSnapWidget();
            observer.disconnect();
          }
        });
      },
      { rootMargin: "0px 0px -5000px 0px" }  // Ajustar el rootMargin según sea necesario
    );
    

    const footerElement = document.querySelector("footer");
    if (footerElement) {
      footerObserver.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        footerObserver.unobserve(footerElement);
      }
    };
  }, []);

  return (
    <LazyLoadComponent>
      <footer className="footer">
        <div className="footer__line"></div>
        <div className="footer__contact">
          <Logo
            css="footer__contact__logo"
            data-aos="zoom-in"
            data-aos-duration="1000"
          />
          <div className="footer__contact__info">
            <p
              className="footer__contact__info__title"
              data-aos="slide-down"
              data-aos-duration="500"
              data-aos-once="true"
            >
              Teatro Luminaria
            </p>
            <p
              className="footer__contact__info__email"
              data-aos="slide-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <a href="mailto:contacto@teatroluminaria.com">
                contacto@teatroluminaria.com
              </a>
            </p>
            <p
              className="footer__contact__info__phone"
              data-aos="slide-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <a href="tel:+34657535101"> 657 535 101</a>
            </p>
            <p
              className="footer__contact__info__whatsapp"
              data-aos="fade"
              data-aos-duration="3500"
              data-aos-once="true"
            >
              <a
                href="https://wa.me/34657535101"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-whatsapp"></i>Contacta por Whatsapp
              </a>
            </p>
          </div>
        </div>
        {/* REDES MOVIL */}
        <div className="footer__banners--mobile" data-aos="fade-up">
          {/* INSTAGRAM */}
          <a href="https://instagram.com/teatro_luminaria" target="blank">
            <picture>
              <source srcSet={InstaMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={InstaMobile} alt="sigInsta" width="100" />
            </picture>
          </a>
          {/* FACEBOOK */}
          <a href="https://www.facebook.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={FaceMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={FaceMobile} alt="sigFace" width="100" />
            </picture>
          </a>
          {/* TIKTOK */}
          <a href="https://www.tiktok.com/@teatroluminaria" target="blank">
            <picture>
              <source srcSet={TikTokMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={TikTokMobile} alt="sigTikTok" width="100"/>
            </picture>
          </a>
          {/* X */}
          <a href="https://x.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={XMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={XMobile} alt="sigX" width="100" />
            </picture>
          </a>
          {/* LINKEDIN */}
          <a
            href="https://es.linkedin.com/company/teatroluminaria"
            target="blank"
          >
            <picture>
              <source srcSet={LinkedinMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={LinkedinMobile} alt="sigLinkedin" width="100"/>
            </picture>
          </a>
          {/* YOUTUBE */}
          <a href="https://www.youtube.com/@teatroluminaria" target="blank">
            <picture>
              <source srcSet={YoutubeMobileWebp} type="image/webp"></source>
              <LazyLoadImage src={YoutubeMobile} alt="sigYoutube" width="100" />
            </picture>
          </a>
        </div>
        {/* REDES DESKTOP */}
        <div className="footer__banners--desktop" data-aos="fade-up">
          {/* INSTAGRAM */}
          <a href="https://instagram.com/teatro_luminaria" target="blank">
            <picture>
              <source srcSet={InstaDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={InstaDesk} alt="sigInsta" width="300"  />
            </picture>
          </a>
          {/* FACEBOOK */}
          <a href="https://www.facebook.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={FaceDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={FaceDesk} alt="sigFace" width="300"  />
            </picture>
          </a>
          {/* TIKTOK */}
          <a href="https://www.tiktok.com/@teatroluminaria" target="blank">
            <picture>
              <source srcSet={TikTokDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={TikTokDesk} alt="SigTikTok" width="300" />
            </picture>
          </a>
          {/* X */}
          <a href="https://x.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={XDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={XDesk} alt="SigTwit" width="300" />
            </picture>
          </a>
          {/* LINKEDIN */}
          <a
            href="https://es.linkedin.com/company/teatroluminaria"
            target="blank"
          >
            <picture>
              <source srcSet={LinkedinDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={LinkedinDesk} alt="SigLinkedin" width="300" />
            </picture>
          </a>
          {/* YOUTUBE */}
          <a href="https://www.youtube.com/@teatroluminaria" target="blank">
            <picture>
              <source srcSet={YoutubeDeskWebp} type="image/webp"></source>
              <LazyLoadImage src={YoutubeDesk} alt="SigYoutube" width="300" />
            </picture>
          </a>
        </div>
        {/* CARRUSEL OPINIONES */}
        <div
          className="footer__reviews"
          data-aos="zoom-in-up"
          data-aos-once="true"
        >
          <h2 className="footer__reviews__title">Opiniones</h2>

          {/* CARRUSEL MOVIL */}
          <Carousel
            type="review"
            ident="carouselReviewMobile"
            interval="10000"
            aos={false}
            buttons={true}
          />

          {/* CARRUSEL DESKTOP */}
          <Carousel
            type="review"
            ident="carouselReviewDesktop"
            interval="30000"
            aos={false}
            buttons={true}
          />
          <a
            className="footer__reviews__button"
            href="https://g.page/r/CRgGVtws0n6tEAg/review"
            target="blank"
          >
            <picture>
              <source srcSet={ReviewButtonWebp} type="image/webp"></source>
              <LazyLoadImage
                className="footer__reviews__button__img"
                src={ReviewButton}
                alt="buttonReview"
                data-aos="flip-up"
                width="300"
              />
            </picture>
          </a>
        </div>
        <div className="footer__widgets">
          {/* Snapwidget Facebook */}

          <div
            className="footer__widgets__face"
            data-aos="slide-right"
            data-aos-once="true"
          >
            <h2 className="footer__widgets__face__title">Nuestro Facebook</h2>
            <div className="footer__widgets__face__frame">
              <iframe
                src="https://snapwidget.com/embed/915224"
                className="snapwidget-widget"
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                title="facebook"
                style={{ height: "100%" }}
              ></iframe>
            </div>
          </div>
          {/* Snapwidget Instagram */}
          <div
            className="footer__widgets__insta"
            data-aos="slide-left"
            data-aos-once="true"
          >
            <h2 className="footer__widgets__insta__title">Nuestro Instagram</h2>
            <div className="footer__widgets__insta__frame">
              <iframe
                src="https://snapwidget.com/embed/915223"
                className="snapwidget-widget"
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                title="instagram"
                style={{ height: "100%" }}
              ></iframe>
            </div>
          </div>
        </div>
        {/* Enlaces Finales */}
        <div className="footer__links">
          <Link to="/avisolegal">Aviso legal</Link>
          <a href="http://www.teatroeningles.es">www.teatroeningles.es</a>
        </div>
      </footer>
      <script src="https://snapwidget.com/js/snapwidget.js"></script>
    </LazyLoadComponent>
  );
}

export default React.memo(Footer);
