import React, { useMemo, Suspense } from "react";
import CarouselElement from "./CarouselElement";
import data from "./carouseldata.json";
import getWindowDimensions from "../WindowDimensions";

function Carousel(props) {
  const dimensions = getWindowDimensions();
  const isSmall = dimensions.width < 768;

  const carouselData = useMemo(() => {
    if (props.ident === "carouselShows" && isSmall) {
      return data.find((item) => item.title === "mainSmall");
    } else if (props.ident === "carouselShows") {
      return data.find((item) => item.title === "main");
    } else if (props.ident === "packAhorro") {
      return data.find((item) => item.title === "packAhorro");
    } else if (props.type === "review") {
      return data.find(
        (item) =>
          item.title ===
          (props.ident === "carouselReviewDesktop" ? "reviewDesktop" : "reviewMobile")
      );
    } else if (props.type === "show" || props.type === "activity") {
      return data.find((item) => item.title === props.ident);
    } else {
      return null;
    }
  }, [props.ident, props.type, isSmall]);

  if (!carouselData) {
    return null;
  }

  const elements = carouselData.content.map((item) => (
    <CarouselElement
      link={item.link}
      image={item.image}
      altern={item.altern}
      active={item.active}
      key={item.altern}
      lazy // Agregamos la prop "lazy" para habilitar la carga diferida en el componente CarouselElement
    />
  ));

  const buttons = props.buttons && (
    <div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={"#" + props.ident}
        data-bs-slide="prev"
      >
        <i className="fas fa-arrow-left prev"></i>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={"#" + props.ident}
        data-bs-slide="next"
      >
        <i className="fas fa-arrow-right next"></i>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div
        id={props.ident}
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval={props.interval}
        data-aos={props.aos && "flip-up"}
        data-aos-duration={props.aos && "500"}
        data-aos-once={props.aos && "true"}
        pause="hover"
      >
        <div className="carousel-inner">{elements}</div>
        {buttons}
      </div>
    </Suspense>
  );
}

export default React.memo(Carousel);
