import { useContext } from "react";
import { Link } from "react-router-dom";
import TitleHeader from "./TitleHeader";
import Logo from "../Logo";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../WindowDimensions";
import { UserContext } from "../UserContext";

function Header() {
  const { detection, setDetection } = useContext(UserContext); // Estado del scroll
  const headerRef = useRef(null); // Referencia del header
  const pseudoRef = useRef(null); // Referencia del pseudoheader
  const { height, width } = useWindowDimensions(); // Mide la ventana general
  const [isClicked, setIsClick] = useState(false); // Estado del click del toggle
  const [isSubShowed, setIsSubShowed] = useState(false); // Estado del click del submenu Teatro

  function isIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf("MSIE "); // IE 10 or older
    const trident = ua.indexOf("Trident/"); // IE 11

    return msie > 0 || trident > 0;
  }

  // Mide las dimensiones de la cabecera
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Borra el timer para medir el componente
  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  // En cuanto carga la pagina, mide el componente
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (headerRef.current) {
        setDimensions({
          width: headerRef.current.offsetWidth,
          height: headerRef.current.offsetHeight,
        });
      }
    }, 500);

    // Llama al resize handler inicialmente
    debouncedHandleResize();

    window.addEventListener("load", debouncedHandleResize);
    window.addEventListener("resize", debouncedHandleResize);

    // Limpieza del evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("load", debouncedHandleResize);
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

  // Controla el scroll para cambiar el estado de detección
  useEffect(() => {
    const handleScroll = () => {
      let scrolled =
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;

      if (headerRef.current) {
        if (scrolled > dimensions.height && width > 992) {
          setDetection(true);
        } else {
          setDetection(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dimensions.height, width, setDetection]);

  // Despliega el menu
  function menuClick() {
    setIsClick(true);
  }

  // Cierra el menu
  function closeClick() {
    setIsClick(false);
  }

  function showSubmenu() {
    setIsSubShowed(true);
  }

  function hideSubmenu() {
    // Añadimos un pequeño retraso para ocultar suavemente el submenú
    setTimeout(() => {
      setIsSubShowed(false);
    }, 100); // Puedes ajustar el tiempo si es necesario
  }

  // Pasa las clase header a headfix del menu
  function headerToFix(bool) {
    if (bool) {
      return isClicked ? "headfix__menu active" : "headfix__menu";
    } else {
      return isClicked ? "header__menu active" : "header__menu";
    }
  }

  return (
    <div>
      <div
        className="pseudoheader"
        style={{
          height: dimensions.height,
          display: width < 992 || detection ? "block" : "none",
        }}
        ref={pseudoRef}
      ></div>
      <header className={detection ? "headfix" : "header"} ref={headerRef}>
        <Logo css={detection ? "headfix__logo" : "header__logo"} />
        <h1 className={detection ? "headfix__h1" : "header__h1"}>
          Teatro Luminaria - Teatro en inglés
        </h1>
        <TitleHeader css={detection ? "headfix__page" : "header__page"} />

        <div className={detection ? "headfix__toggle" : "header__toggle"}>
          <i
            className="fas fa-bars"
            style={{ display: isClicked ? "none" : "" }}
            onClick={menuClick}
          ></i>
          <i
            className="fas fa-times"
            style={{ display: isClicked ? "" : "none" }}
            onClick={closeClick}
          ></i>
          <i
            className="fas fa-arrow-alt-circle-up"
            onClick={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                setDetection(false);
              }, 1000);
            }}
          ></i>
        </div>

        <ul
          className={detection ? headerToFix(true) : headerToFix(false)}
          style={{ top: dimensions.height + 2 }}
        >
          <div className="header__menu__frame">
            <Link to="/" onClick={closeClick}>
              <li className="header__menu__frame__item">Inicio</li>
            </Link>

            <div
              className="header__menu__frame__item--desktop"
              onMouseOver={showSubmenu}
              onMouseOut={hideSubmenu}
            >
              <li className="header__menu__frame__item--desktop--sub">
                <span>Teatro</span>
              </li>
              <ul
                className={`header__menu__frame__item--desktop--sub--submenu ${
                  isSubShowed ? "show" : ""
                }`}
              >
                <Link to="/teatroingles" onClick={hideSubmenu}>
                  <li className="header__menu__frame__item--desktop--sub--submenu__item">
                    Teatro en inglés
                  </li>
                </Link>
                <Link to="/teatro" onClick={hideSubmenu}>
                  <li className="header__menu__frame__item--desktop--sub--submenu__item">
                    Teatro en castellano
                  </li>
                </Link>
              </ul>
            </div>
            <div className="header__menu__frame__item--nodesktop">
              <Link to="/teatroingles" onClick={closeClick}>
                <li className="header__menu__frame__item">Teatro en inglés</li>
              </Link>
              <Link to="/teatro" onClick={closeClick}>
                <li className="header__menu__frame__item">
                  Teatro en castellano
                </li>
              </Link>
            </div>
            <Link to="/cuentacuentos" onClick={closeClick}>
              <li className="header__menu__frame__item">Cuentacuentos</li>
            </Link>
            <Link to="/talleres" onClick={closeClick}>
              <li className="header__menu__frame__item">Talleres</li>
            </Link>
            <Link to="/compania" onClick={closeClick}>
              <li className="header__menu__frame__item">Compañía</li>
            </Link>
            <Link to="/blog" onClick={closeClick}>
              <li className="header__menu__frame__item">Blog</li>
            </Link>
            <Link to="/contacto" onClick={closeClick}>
              <li className="header__menu__frame__item">Contacto</li>
            </Link>
          </div>
        </ul>
      </header>
      {isIE() ? (
        <div style={{ position: "fixed", zIndex: "5" }}>
          <p style={{ color: "blue", textAlign: "center", fontSize: "12px" }}>
            Para una correcta navegación y visualización,
            <br /> valore usar otro navegador como Chrome, Firefox o Edge
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
