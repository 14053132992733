import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/commons/Header/Header";
import Footer from "./components/commons/Footer/Footer";
import ScrollToTop from "./components/commons/ScrollToTop";
import { UserProvider } from "./components/commons/UserContext";
import FloatingBanner from "./components/commons/FloatingBanner";

// Lazy load components
const Home = lazy(() => import("./components/Home"));
const Shows = lazy(() => import("./components/ShowsMenu/Shows"));
const Show = lazy(() => import("./components/Shows/Show"));
const Activity = lazy(() => import("./components/Activities/CuentaTalleres"));
const Blog = lazy(() => import("./components/Blog/Blog"));
const Company = lazy(() => import("./components/Company/Company"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Legal = lazy(() => import("./components/Legal/Legal"));
const Page404 = lazy(() => import("./components/commons/page404"));
const Pack = lazy(() => import("./components/Pack/Pack"));
const Regalo = lazy(() => import("./components/Regalo/Regalo"));
const Landing = lazy(() => import("./components/Landing/LandingPage"));

function Layout() {
  const location = useLocation();

  // Condicionalmente mostramos el Header y el Footer dependiendo de la ruta actual
  const shouldShowHeaderFooter = location.pathname !== "/oferta-educacion";

  return (
    <>
      {shouldShowHeaderFooter && <Header />}
      <Routes>
        <Route exact path="/" element={<Home dirpath="home" />} />
        <Route path="/teatroingles" element={<Shows dirpath="ingles" />} />
        <Route path="/teatro" element={<Shows dirpath="castellano" />} />
        <Route path="/acall" element={<Show dirpath="acall" />} />
        <Route path="/atrapada" element={<Show dirpath="atrapada" />} />
        <Route path="/avisolegal" element={<Legal dirpath="avisolegal" />} />
        <Route path="/beeyourself" element={<Show dirpath="beeyourself" />} />
        <Route path="/blacklight" element={<Show dirpath="blacklight" />} />
        <Route path="/blacklight2" element={<Show dirpath="blacklight2" />} />
        <Route path="/blog" element={<Blog dirpath="blog" />} />
        <Route path="/teatro-en-ingles" element={<Blog dirpath="teatro-en-ingles" />} />
        <Route path="/bouchontraveller" element={<Show dirpath="bouchontraveller" />} />
        <Route path="/bouchonviajero" element={<Show dirpath="bouchonviajero" />} />
        <Route path="/compania" element={<Company dirpath="compania" />} />
        <Route path="/contacto" element={<Contact dirpath="contacto" />} />
        <Route path="/cookies" element={<Legal dirpath="cookies" />} />
        <Route path="/cuentacuentos" element={<Activity dirpath="cuentacuentos" />} />
        <Route path="/emotion" element={<Show dirpath="emotion" />} />
        <Route path="/packahorro" element={<Pack dirpath="packahorro" />} />
        <Route path="/quest" element={<Show dirpath="quest" />} />
        <Route path="/sinfonias" element={<Show dirpath="sinfonias" />} />
        <Route path="/sinfonias2" element={<Show dirpath="sinfonias2" />} />
        <Route path="/storytelling" element={<Show dirpath="storytelling" />} />
        <Route path="/talleres" element={<Activity dirpath="talleres" />} />
        <Route path="/trapped" element={<Show dirpath="trapped" />} />
        <Route path="/violet" element={<Show dirpath="violet" />} />
        <Route path="/oferta-educacion" element={<Landing dirpath="oferta-educacion" />} />
        <Route path="/regalo/sinfonias" element={<Regalo dirpath="regalo_sinfonias" />} />
        <Route path="/regalo/sinfonias2" element={<Regalo dirpath="regalo_sinfonias2" />} />
        {/* BLOG */}
        <Route path="/blog/bienvenidos-blog" element={<Blog dirpath="bienvenidos-blog" />} />
        <Route path="/blog/innovacion-teatro-aprendizaje-ingles" element={<Blog dirpath="innovacion-teatro-aprendizaje-ingles" />} />
        <Route path="/blog/juegos-cortos-clase-ingles" element={<Blog dirpath="juegos-cortos-clase-ingles" />} />
        <Route path="/blog/lesson-plan-creatividad-ingles-chefcraft" element={<Blog dirpath="lesson-plan-creatividad-ingles-chefcraft" />} />
        <Route path="/blog/desarrollo-lenguaje-utero-1" element={<Blog dirpath="desarrollo-lenguaje-utero-1" />} />
        <Route path="/blog/desarrollo-lenguaje-nacimiento-2" element={<Blog dirpath="desarrollo-lenguaje-nacimiento-2" />} />
        <Route path="/blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-1" element={<Blog dirpath="la-importancia-del-teatro-en-ingles-para-colegios-parte-1" />} />
        <Route path="/blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-2" element={<Blog dirpath="la-importancia-del-teatro-en-ingles-para-colegios-parte-2" />} />
        {/* 404 */}
        <Route path="*" element={<Page404 dirpath="page404" />} />
      </Routes>
      {shouldShowHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="backcontainer">
      <UserProvider>
        <Router>
          <ScrollToTop />
          <Suspense fallback={<div>Loading...</div>}>
            <Layout />
          </Suspense>
          <FloatingBanner />
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
