import React, { useState, useEffect } from "react";

const FloatingBanner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect to handle window resize events
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bannerStyle = {
    position: "fixed",
    bottom: "10px", // For large screens
    right: "10px",  // Floating on the right for large screens
    backgroundColor: "#ffcc00",
    color: "#333",
    padding: "15px 25px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    fontWeight: "bold",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap", // Prevent text from breaking in large screens
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#333",
  };

  // Style adjustments for tablets (width <= 768px)
  const tabletStyle = {
    width: "70%", // Occupy full width on tablets
    fontSize: "14px",
    padding: "15px 30px",
    whiteSpace: "nowrap", // Keep the text in one line for tablets
  };

  // Style adjustments for mobile (width <= 480px)
  const mobileStyle = {
    width: "100%", // Occupy the full width of the mobile
    fontSize: "14px",
    padding: "20px", // Increased padding for prominence on mobile
    bottom: "0px", // Keep it at the bottom of the mobile screen
    right: "0px", // No right margin, occupy full width
    height: "auto", // Automatically adjust the height
    borderRadius: "0", // No rounded corners on mobile
    textAlign: "center", // Center text on mobile
    whiteSpace: "normal", // Allow text to wrap in mobile
    lineHeight: "1.2", // Adjust line height for better readability
  };

  // Determine the appropriate style based on window width
  let currentStyle;

  if (windowWidth <= 480) {
    currentStyle = { ...bannerStyle, ...mobileStyle };
  } else if (windowWidth <= 768) {
    currentStyle = { ...bannerStyle, ...tabletStyle };
  } else {
    currentStyle = bannerStyle;
  }

  return (
    <div style={currentStyle}>
      <a
        href="https://www.educaciontrespuntocero.com/empresas/teatro-luminaria/"
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
      >
        {windowWidth <= 480 ? (
          <>
            🎉 ¡Lee el artículo de Educación 3.0 <br />
            sobre Teatro Luminaria! 🎭
          </>
        ) : (
          "🎉 ¡Lee el artículo de Educación 3.0 sobre Teatro Luminaria! 🎭"
        )}
      </a>
    </div>
  );
};

export default FloatingBanner;
